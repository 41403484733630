import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'
//将axios挂载到原型
Vue.prototype.$axios = axios
// import "lib-flexible"
// import './utils/flexible'
//表单拖拽排序
 // import './utils/rem.js'
Vue.prototype.$store = store

//引入字体
import './assets/font/font.css'

Vue.config.productionTip = false

Vue.directive('title', {
	inserted: function(el, binding) {
		document.title = el.dataset.title
	},
})

Vue.directive('icon', {
	inserted: function(item, index) {
		const changeFavicon = (link) => {
			let $favicon = document.querySelector('link[rel="icon"]')
			if ($favicon !== null) {
				$favicon.href = link
			} else {
				$favicon = document.createElement('link')
				$favicon.rel = 'icon'
				$favicon.href = link
				document.head.appendChild($favicon)
			}
		}
		let iconUrl = item.dataset.icon
		console.log(item.dataset);
		changeFavicon(iconUrl)
	},
})



Vue.use(ElementUI, axios, VueAxios)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
