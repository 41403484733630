import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)
const getDefaultState = () => {
	  return {
	   avatar: '',
	   id: '',
	   name: '',
	   username: '',
	   parentid: 0,
	   list: [],
	   float: 0, //浮动系数
	   isshow: 0,
	   taxincluded: 0, //s税率
	   uti: 0,
	   symbol:'',
	   value1: false,
	   mor:'默认数据',
	   changjiaddatalist:[],
	  }
	}
export default new Vuex.Store({
	
	state:getDefaultState(),
	mutations: {
	 RESET_STATE: (state) => {
	    Object.assign(state, getDefaultState())
	  },
		listdata(state, data) {
			state.list = data
		},
		price_manufactor(state, data) {
			state.changjiaddatalist = data
		},
		morshuju(state, data) {
			state.symbol = data.symbol
			state.taxincluded = Number(data.tax_rate) 
			
		},
		_value1(state, data) {
			state.uti = data.uti
			state.value1 = data.value
		},
		getUserInfo(state, data) {
			state.avatar = data.avatar,
				state.id = data.id,
				state.name = data.name,
				state.phone = data.phone
			state.username = data.username
		},
		factorydata1(state, data) {
			state.float = data
		},
		factorydata(state, data) {
			state.mor = data.name,
			state.symbol = data.symbol
			state.parentid = data.index_id,
				state.float = data.float,
				state.isshow = data.is_show
			state.taxincluded = Number(data.tax_included)
		},
		getUserInfos(state, data) {
			state.checked = data
		}
	},
	actions: {},
	modules: {}
})
