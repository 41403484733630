import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		meta: {
			title: '易为易线缆报价系统',
			icon: 'https://ywy.souxianlan.com/logo.jpg'
		},
		component: () => import('../views/LoginName.vue'),
	},
	{
		path: '/about',
		meta: {
			title: '详情页',
			icon: 'https://ywy.souxianlan.com/logo.jpg'
		},
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
	},
]

const router = new VueRouter({
	routes,
	// mode: 'history',
	base: process.env.BASE_URL,
})

router.afterEach((to, from) => {
	document.title = to.meta.title,
		document.icon = to.meta.icon
})

export default router
