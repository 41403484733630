<template>
	<div id='app' ref="app">
		<router-view  />
	</div>
</template>
<script>
	import wudi from 'lodash'
	export default {
		
	
		
		// mounted() {
			
		//     this.$nextTick(() => {
		//       const $app = this.$refs.app
			  
		//       const standardScale = 1080 / 1920
		
		//       window.addEventListener(
		//         'resize',
		//         wudi.debounce(function () {
		//           const docHeight = document.body.clientHeight
		//           const docWidth = document.body.clientWidth
		
		//           if (docWidth < 1680) {
		//             const currentScale = docHeight / docWidth
		//             let [scale, translate] = [0, 0]
		//             if (currentScale < standardScale) {
		//               // 以高度计算
		//               scale = docHeight / 1090
		//               const shouleWidth = 1920 * scale
		//               const offsetWidth = docWidth - shouleWidth
		//               translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
		//             } else {
		//               // 以宽度计算
		//               scale = docWidth / 1920
		//               const shouleHeight = 1080 * scale
		//               const offsetHeight = docHeight - shouleHeight
		//               translate = offsetHeight > 0 ? `translate( 0,0)` : ''
		//             }
		//             console.log(scale,translate)
		//             $app.style.cssText = `
		//             transform: scale(${scale}) ${translate};
		//             transform-origin: top left;
		//             min-width: 1920px;
		//             min-height: 1080px;
		//           `
		//           } else {
		//             $app.style.cssText = ''
		//           }
		//         }),
		//         200
		//       )
		
		//       if (document.createEvent) {
		//         var event = document.createEvent('HTMLEvents')
		//         event.initEvent('resize', true, true)
		//         window.dispatchEvent(event)
		//       } else if (document.createEventObject) {
		//         window.fireEvent('onresize')
		//       }
		//     })
		//   },


		created() {
			//在页面加载时读取sessionStorage里的状态信息
			if (sessionStorage.getItem('store')) {
				this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem(
					'store'))));
			}

			//在页面刷新时将vuex里的信息保存到sessionStorage里
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem('store', JSON.stringify(this.$store.state));
			});
		}

	}
</script>
<style lang="scss">
	#app {
		
		font-family: 'PingFang-RE';
		font-display: swap;
	}
	html,
	body {
		margin: 0;
		padding: 0;
		border: 0;
	}
</style>
